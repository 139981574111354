<template>
  <v-app>
    <v-dialog
      v-model="dialogLoading"
      hide-overlay
      v-if="loading"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          <h2 class="white--text">Loading</h2>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>

        <v-btn
          @click.stop="
            Export2Doc('exportContent', 'ตำแหน่งว่างหลังการพิจารณาย้าย')
          "
          class="mr-1"
          color="blue"
        >
          <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
        </v-btn>
        <v-btn
          @click.stop="
            exportToExcel('exportContent', 'ตำแหน่งว่างหลังการพิจารณาย้าย')
          "
          class="mr-1"
          color="green"
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to excel
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent">
      <div class="page">
        <div class="head text-center">
          แบบบันทึกคะแนน องค์ประกอบ ตัวชี้วัดและคะแนนในการประเมินตามองค์ประกอบ
          <br />
          การประเมินศักยภาพของผู้ประสงค์ขอย้าย <br />
          ตำแหน่งครู (การย้ายกรณีปกติ) ตัวชี้วัดที่ 1.4 ขององค์ประกอบที่ 1
        </div>
        <table class="text_j table" width="100%" align="center">
          <tr>
            <td width="5%" class="text-center table bold16">ลำดับที่</td>
            <td width="17%" class="text-center table bold16">ชื่อ-นามสกุล</td>
            <td width="20%" class="text-center table bold16">สถานศึกษา</td>
            <td width="5%" class="text-center table bold16">เลขที่ตำแหน่ง</td>
            <td width="8%" class="text-center table bold16">ประธานกรรมการ</td>
            <td width="8%" class="text-center table bold16">
              กรรมการ <br />
              คนที่ 2
            </td>
            <td width="8%" class="text-center table bold16">
              กรรมการ <br />
              คนที่ 3
            </td>
            <td width="8%" class="text-center table bold16">
              กรรมการ <br />
              คนที่ 4
            </td>
            <td width="8%" class="text-center table bold16">
              กรรมการ <br />
              คนที่ 5
            </td>
            <td width="10%" class="text-center table bold16">คะแนนเฉลี่ย</td>
          </tr>
          <tr
            v-for="(item, index) in transference_personnels"
            :key="item.id_tfp"
          >
            <td class="table text-center">{{ index + 1 }}</td>
            <td class="table pa-1">
              {{ item.title_s + item.frist_name + " " + item.last_name }}
            </td>
            <td class="table pa-1">
              {{ item.college_name }}
            </td>
            <td class="text-center table">
              {{ item.id_position }}
            </td>
            <td class="table"></td>
            <td class="table"></td>
            <td class="table"></td>
            <td class="table"></td>
            <td class="table"></td>
            <td class="table"></td>
          </tr>
        </table>

        <br />

        <div class="head">
          รับรองข้อมูลตามแบบบันทึกผลรวมคะแนนการประเมินคุณลักษณะที่จำเป็นต่อการปฏิบัติงาน
        </div>
<br>
<table width="100%" class="text_j">
          <tr>
            <td class="text-center" width="100%">
              ลงชื่อ..........................................................................
            </td>     
          </tr>
          <tr>
            <td class="text-center">
              ( นายสง่า แต่เชื้อสาย )
            </td>
    
          </tr>
          <tr>
            <td class="text-center">ประธานกรรมการ</td>
         
          </tr>
        </table>

<br>
        <table width="100%" class="text_j">
          <tr>
            <td class="text-center" width="35%">
              ลงชื่อ..............................................
            </td>
            <td class="text-center" width="35%">
              ลงชื่อ..............................................
            </td>
            <td class="text-center" width="30%">
              ลงชื่อ..............................................
            </td>
          </tr>
          <tr>
            <td class="text-center">
              ( นายวิทวัต ปัญจมะวัต )
            </td>
            <td class="text-center">
              ( นายราตรีสวัสดิ์  ธนานันต์ )
            </td>
            <td class="text-center">
              ( นายสุรพงษ์  เอิมอุทัย )
            </td>
          </tr>
          <tr>
            <td class="text-center">กรรมการ</td>
            <td class="text-center">กรรมการ</td>
            <td class="text-center">กรรมการ</td>
          </tr>
        </table>
        <br>
        <table width="100%" class="text_j">
          <tr>
            <td class="text-center" width="35%">
              ลงชื่อ..............................................
            </td>
            <td class="text-center" width="35%">
              ลงชื่อ..............................................
            </td>
            <td class="text-center" width="30%">
              ลงชื่อ..............................................
            </td>
          </tr>
          <tr>
            <td class="text-center">
              ( นายสิรวิชญ์ ธนเศรษฐ์วงศ์ )
            </td>
            <td class="text-center">
              ( นายประดิษฐ์ ญาณประเสริฐ )
            </td>
            <td class="text-center">
              ( นายพรรษา ฉายกล้า )
            </td>
          </tr>
          <tr>
            <td class="text-center">กรรมการ</td>
            <td class="text-center">กรรมการ</td>
            <td class="text-center">กรรมการ</td>
          </tr>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    loading: true,
    dialogLoading: false,
    transference_personnels: []
  }),

  async mounted() {
    await this.sweetAlertLoading()
    await this.transferenceQuery();
    Swal.close();
  },

  methods: {
    async transferenceQuery() {
      let result = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        time_s: this.times,
        year_s: this.years,   
        type_move:"nm"
      });
      this.transference_personnels = result.data;
    },

    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },



    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    },
    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    times() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },
    years() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[7];
    },
    date_today() {
      let today = new Date();
      return today;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.div_j {
  border-collapse: collapse;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}
p {
  text-indent: 50px;
}
td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 29.7cm;
  min-height: 21cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 0.5cm;
  padding-left: 1cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.bold14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}
.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
                height: 247mm;
              }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}
.text_al {
  text-align: left;
  vertical-align: top;
}
#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}
.tab1 {
  padding-left: 4em;
}
.textAlignVer {
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  margin-bottom: 2px;
}
@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
    page-break-after: always;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
                  font-size: 18px;
                  font-weight: bold;
                }
                .regular12 {
                  font-size: 12px;
                }
                .blod12 {
                  font-size: 12px;
                  font-weight: bold;
                }
                .blackRegula8 {
                  font-size: 8px;
                } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
